import React from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Space,
  Button,
  Modal,
  useModal,
  Form,
  Input,
} from "@pankod/refine-antd";
import { useNavigation, usePermissions } from "@pankod/refine-core";
import { PermissionsConstants } from "constants/permissions";

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
  const { list } = useNavigation();
  const { data: permissionsData } = usePermissions();
  console.log("permissions data => ", permissionsData);

  return (
    <Row gutter={[16, 16]}>
        <Col xs={24} md={12} lg={8}>
          <Card
            title="Eventos"
            style={{ height: "150px", borderRadius: "15px" }}
            headStyle={{ textAlign: "center" }}
            bodyStyle={{ textAlign: "center" }}
          >
            <Space align="center" direction="horizontal">
              <Text><Button type="primary" onClick={ () => list('events')}>Entrar</Button></Text>
            </Space>
          </Card>
        </Col>
      </Row>
  );
};