import { Refine } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";

import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
  ConfigProvider,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import dataProvider from "@pankod/refine-simple-rest";
import axios, { AxiosInstance, AxiosRequestConfig } from "@pankod/refine-simple-rest/node_modules/axios";
import { authProvider } from "./providers/auth-provider.provider";
import { Login } from "pages/login";
import { DashboardPage } from "pages/dashboard";
import { Sider } from "components/organisms/sider.component";

import './sass/main.scss';

import { API_URL, PERMISSIONS_KEY, TOKEN_KEY } from "constants/environment";
import { accessControlProvider } from "providers/access-control.provider";
import { useState } from "react";
import moment from "moment-timezone";
import locale from "antd/lib/locale/es_ES";
import { UserOutlined } from "@ant-design/icons";
import { CustomPage } from "pages/custom/custom-page";

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
        if (request.headers) {
            request.headers["Authorization"] = `Bearer ${token}`;
        } else {
            request.headers = {
                Authorization: `Bearer ${token}`,
            };
        }
    }
    return request;
});



function App() {
  const lsPermissions = localStorage.getItem(PERMISSIONS_KEY);
  const [permissions, setPermissions] = useState(lsPermissions || '');
  moment.tz.setDefault('America/Mazatlan');
  
  return (
    <ConfigProvider locale={locale}>
      <Refine
      notificationProvider={notificationProvider}
      Layout={Layout}
      Sider={Sider}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            element: <CustomPage />,
            path: '/custom'
          }
        ] as typeof routerProvider.routes,
      }}
      accessControlProvider={accessControlProvider(permissions)}
      dataProvider={dataProvider(API_URL, axiosInstance)}
      authProvider={authProvider(axiosInstance, setPermissions)}
      LoginPage={Login}
      DashboardPage={DashboardPage}
      reactQueryDevtoolConfig={false}
      resources={[
        {
          parentName: "",
          name: "events",
          icon: <UserOutlined />,
          list: CustomPage,
          options: {
            label: "Eventos",
            route: "events"
          },
        }
      ]}
    />
    </ConfigProvider>
  );
}

export default App;
